import React from 'react';
import Layout from '../components/layout';
import MultimediaPage from '../components/MultimediaPage/multimediaPage';
import SEO from '../components/SEO/SEO';

const Multimedia = () => {
  return (
    <Layout>
      <SEO
        title='Multimedia'
        description='Smartphone und Multimedia Kompatibilität für Easy Link, R-LINK Evolution, Carminat TomTom, R-LINK 2, Media Nav, Radio Connect R & GO.'
      />
      <MultimediaPage />
    </Layout>
  );
};

export default Multimedia;
