import React, { useState } from 'react';
import * as styles from './modalContent.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import CarminatImg from '../../images/multimedia/R-LINK-H1.jpg';
import CarminatTomTom from '../../images/multimedia/carminat.jpg';

const ModalContent = ({ isOpen }) => {
  const [show, setShow] = useState(isOpen);

  const handleClose = () => setShow(!isOpen);

  return (
    <>
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-center'>
              Wichtige Nachricht für Renault Kunden mit
              <strong> R-LINK Evolution</strong> und
              <strong> Carminat TomTom</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={`${styles.row}`}>
              <div className='col-sm-4 p-2'>
                <a
                  href='https://www.renault.de/renault-connect/softwareupdate-r-link-evolution.html'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img className='img-fluid' src={CarminatImg} alt='carminat' />
                </a>
                <h6 className='text-center mt-2 pt-2'>
                  <a
                    href='https://www.renault.de/renault-connect/softwareupdate-r-link-evolution.html'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    R-LINK Evolution
                  </a>
                </h6>
              </div>

              <div className='col-sm-4 p-2'>
                <a
                  href='https://www.renault.de/renault-connect/faq-carminat-tomtom.html'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    className='img-fluid image-shadow'
                    src={CarminatTomTom}
                    alt='carminat TomTom'
                  />
                </a>
                <h6 className='text-center mt-2 pt-2'>
                  <a
                    href='https://www.renault.de/renault-connect/faq-carminat-tomtom.html'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Carminat TomTom
                  </a>
                </h6>
              </div>
            </div>
            <p>
              Ist Ihr Fahrzeug mit einem dieser Systeme ausgestattet, bitten wir
              Sie ein Software Update durchzuführen. Um Störungen zu vermeiden
              muss dies vor dem
              <strong> 6. April 2019 </strong>
              erfolgen. Bitte führen Sie das Softwareupdate(WRNO) ab sofort
              durch damit der reibungslose Betrieb Ihres Navigationssystems
              sichergestellt ist. Das Update ist einfach und rasch
              durchzuführen.
            </p>
            <h6>
              <strong>Was können Sie tun?</strong>
            </h6>
            <ol>
              <li>
                Folgen Sie der
                <Link to='https://www.renault.de/renault-connect.html'>
                  <span className='spanBold'> Anleitung </span>
                </Link>
                um Ihr System zu aktualisieren. Dauer des Update ca. 20-30 min.
              </li>
              <li>
                <p>
                  Kommen Sie auf einen Besuch und Kaffee bei uns vorbei uns
                  lassen Sie bei Ihrem Fahrzeug einen professionellen
                  <strong> Multimedia Check </strong> für
                  <strong> 49.- SRF </strong>
                  durchführen.
                </p>
              </li>
            </ol>
            <div>
              <h6>
                <strong>Dieser Beinhaltet:</strong>
              </h6>
              <ul>
                <li>Durchführung des WRNO Systemupdates</li>
                <li>Prüfung des Kartenmaterials auf Aktualität</li>
                <li>Zeitumstellung ab 31.3.19</li>
                <li>Handy Koppelung/Einrichten</li>
              </ul>
            </div>
            <p>
              Auf Grund einer Limitierung des GPS Zeitsignals im R-LINK oder
              Carminat TomTom, genannt Week Number Roll Over (WNRO), müssen die
              genannten Navi-Systeme für eine weiterhin einwandfreie Funktion
              unbedingt vor dem 6. April 2019 aktualisiert werden.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className='button-md' onClick={handleClose}>
              Schliessen
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ModalContent;
