// extracted by mini-css-extract-plugin
export var buttonContainer = "compabilityPage-module--buttonContainer--4a199";
export var buttonMd = "compabilityPage-module--button-md--f7657";
export var carousel = "compabilityPage-module--carousel--a536c";
export var compabilitySection = "compabilityPage-module--compabilitySection--57400";
export var grey = "compabilityPage-module--grey--03032";
export var greyFont = "compabilityPage-module--greyFont--4eb57";
export var headerContainer = "compabilityPage-module--headerContainer--b2482";
export var row = "compabilityPage-module--row--65450";
export var spanBold = "compabilityPage-module--spanBold--3db12";
export var yellow = "compabilityPage-module--yellow--f36f6";