export default [
  {
    text: 'Benutzerhandbücher',
    link: 'https://ch.e-guide.renault.com/deu',
  },
  {
    text: 'Multimedia Guide',
    link: 'https://de.renault.ch/renault-connect.html',
  },
  {
    text: 'R Link Store',
    link: 'https://myr-de.renault.ch/r-link-store.html',
  },
];
