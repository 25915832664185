import React from 'react';
import Radios from './radios';
import CompabilityPage from './compabilityPage';
import RedBanner from './redBanner';

const MultimediaPage = () => {
  return (
    <section>
      <RedBanner />
      <Radios />
      <CompabilityPage />
    </section>
  );
};

export default MultimediaPage;
