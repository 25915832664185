import React from 'react';
import * as styles from './radios.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const MultimediaPage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulMultimedia(sort: { fields: createdAt, order: ASC }) {
        nodes {
          buttonLink
          buttonLink1
          buttonLink2
          buttonLink3
          buttonLink4
          buttonText
          buttonText1
          buttonText2
          buttonText3
          buttonText4
          name
          img {
            gatsbyImage(width: 500, quality: 100, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const {
    allContentfulMultimedia: { nodes },
  } = data;

  return (
    <section>
      <div className='container'>
        {nodes.map((item, index) => {
          return (
            <div className={`row ${styles.row}`}>
              <div className='col-sm p-3'>
                <GatsbyImage image={item.img.gatsbyImage} />
              </div>
              <div className={`col-sm ${styles.headerButtonsContainer}`}>
                <h3 className='spanBold'>{item.name}</h3>
                <a href={item.buttonLink}>
                  <button className='button-md'>{item.buttonText}</button>
                </a>
                {item.buttonText2 && (
                  <a href={item.buttonLink2}>
                    <button className='button-md'>{item.buttonText2}</button>
                  </a>
                )}
                {item.buttonText3 && (
                  <a href={item.buttonLink3}>
                    <button className='button-md'>{item.buttonText2}</button>
                  </a>
                )}
                {item.buttonText4 && (
                  <a href={item.buttonLink2}>
                    <button className='button-md'>{item.buttonText4}</button>
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default MultimediaPage;
