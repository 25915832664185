import React, { useState } from 'react';
import * as styles from './redBanner.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons';

import ModalContent from './modalContent';

const RedBanner = () => {
  const [showModal, setShowModal] = useState(false);
  const clickHandler = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={styles.redBanner}>
      <div className='row justify-content-center'>
        <h4 className='p-2 text-center' onClick={clickHandler}>
          R-LINK Evolution / Carminat TomTom: Klicken Sie hier für weitere
          Informationen
          <FontAwesomeIcon icon={faHandPointLeft} className='p-1' />
        </h4>
      </div>
      {showModal && <ModalContent isOpen={showModal} />}
    </div>
  );
};

export default RedBanner;
