import React from 'react';
import * as styles from './compabilityPage.module.scss';
import multimediaData from '../../config/libs/multimediaData';

const CompabilityPage = () => {
  return (
    <section className={styles.compabilitySection}>
      <div className='container'>
        <div className={`row ${styles.row}`}>
          <h3>Smartphone und Multimedia Kompatibilität</h3>
          <h6>
            Prüfen Sie die Kompatibilität zwischen Ihrem Handy und dem
            Mediensystem in Ihrem Fahrzeug
          </h6>
          <div className={`col ${styles.buttonContainer}`}>
            {Object.values(multimediaData).map((item, index) => (
              <a href={item.link}>
                <button className='button-md' key={index} value={item}>
                  {item.text}
                </button>
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompabilityPage;
